<template>
  <div>
    <v-row align="center" class="mx-1 my-3">
      <v-col cols="12" md="3">
        <v-text-field
          v-debounce:500ms="getItems"
          class="mr-1"
          v-model="fullName"
          :label="$t('bhth-bwasth-alism-althlathy')"
          hide-details=""
          prepend-inner-icon="search"
          dense=""
          outlined=""
          filled=""
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          @click:clear="isReject = ''"
          v-model="isReject"
          clearable
          @input="getItems()"
          item-text="key"
          item-value="value"
          :items="[
            { key: $t('almqbwlyn'), value: false },
            { key: $t('almrfwdhyn'), value: true },
          ]"
          class="mr-1"
          :label="$t('halh-alzaer')"
          hide-details=""
          prepend-inner-icon="search"
          dense=""
          outlined=""
          filled=""
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          @click:clear="IsAttend = ''"
          v-model="IsAttend"
          clearable
          @input="getItems()"
          item-text="key"
          item-value="value"
          :items="[
            { key: 'غير الحاضرين', value: false },
            { key: 'الحاضرين', value: true },
          ]"
          class="mr-1"
          :label="$t('alhdhwr')"
          hide-details=""
          prepend-inner-icon="search"
          dense=""
          outlined=""
          filled=""
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          @click:clear="HasCar = ''"
          v-model="HasCar"
          clearable
          @input="getItems()"
          item-text="key"
          item-value="value"
          :items="[
            { key: 'الحضور بدون عجلة', value: false },
            { key: 'الحضور بعجلة', value: true },
          ]"
          class="mr-1"
          :label="$t('alajlat')"
          hide-details=""
          prepend-inner-icon="search"
          dense=""
          outlined=""
          filled=""
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          @input="getItems()"
          clearable
          v-model="type"
          dense
          item-text="key"
          item-value="key"
          :items="[
            { key: 'بوابة الB3' },
            { key: 'بوابة الموظفين' },
            { key: 'بوابة VIP' },
            { key: 'بوابة  VIP الداخلية' },
            { key: 'بوابة الموظفين الداخلية' },
          ]"
          placeholder="نوع التخويل"
          filled
          outlined
          hide-details="auto"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              :label="$t('wqt-bdayh-altkhwyl')"
              outlined
              filled
              append-icon="mdi-calendar"
              readonly
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
              v-model="startAt"
            ></v-text-field>
          </template>
          <v-date-picker
            @input="getItems()"
            v-model="startAt"
            locale="ar"
            color="primary"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="3">
        <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              :label="$t('wqt-anthaa-altkhwyl')"
              outlined
              filled
              append-icon="mdi-calendar"
              readonly
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
              v-model="expireAt"
            ></v-text-field>
          </template>
          <v-date-picker
            @input="getItems()"
            v-model="expireAt"
            locale="ar"
            color="primary"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="3">
        <v-btn
          :to="
            'PermitReportsPrint/Sequence=3&IsDeleted=false&Sort=desc&PageIndex=1&PageSize=' +
            count +
            '&FullName=' +
            fullName +
            '&IsReject=' +
            (isReject == null ? '' : isReject) +
            '&IsAttend=' +
            (IsAttend == null ? '' : IsAttend) +
            '&Type=' +
            (type == null ? '' : type) +
            '&HasCar=' +
            (HasCar == null ? '' : HasCar) +
            '&ExpireAt=' +
            expireAt +
            '&StartAt=' +
            startAt
          "
          block
          color="primary"
        >
          <h3>{{ $t('tbaah-tqryr') }}</h3>
        </v-btn>
      </v-col>
      <!-- <v-col cols="12" md="3">
            <v-autocomplete @input="getItems()" clearable v-model="userGroups" :items="$global.state.groups" item-text="name" item-value="id" label="الجهة" hide-details="auto" outlined filled small-chips></v-autocomplete>
        </v-col> -->
    </v-row>

    <v-card outlined>
      <h3 class="text-center mt-4">
        -- {{ $t('alntyjh') }}:
        <v-chip label>
          <h3>{{ count }}</h3>
        </v-chip>
        --
      </h3>
      <v-data-table
        class="mytable"
        :items-per-page="$global.state.filter.pageSize"
        :items="items"
        :loading="$global.state.loading"
        :headers="headers"
        hide-default-footer
        :loading-text="$t('loading')"
        :no-data-text="$t('no-data')"
        :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
      >
        <template v-slot:item.attends="{ item }">
          <div class="teal--text" v-if="item.attends.length > 0">
            {{
              new Date(item.attends[0].time)
                .toLocaleDateString("sv")
                .split("T")[0]
            }},
            <span>{{
              new Date(item.attends[0].time).toLocaleString().split(",")[1]
            }}</span>
          </div>
          <div v-else>
            <h4>لم يحضر</h4>
          </div>
        </template>

        <template v-slot:item.type="{ item }">
          <div v-for="(type, i) in item.type" :key="i">
            <v-row class="ma-1">
              <v-chip
                small
                label
                dark
                v-if="type == 'بوابة الB3'"
                color="orange"
                >{{ type }}</v-chip
              >
              <v-chip
                small
                label
                dark
                v-if="type == 'بوابة الموظفين'"
                color="indigo"
                >{{ type }}</v-chip
              >
              <v-chip small label dark v-if="type == 'بوابة VIP'" color="red">{{
                type
              }}</v-chip>
              <v-chip
                small
                label
                dark
                v-if="type == 'بوابة الموظفين الداخلية'"
                color="indigo"
                >{{ type }}</v-chip
              >
              <v-chip
                small
                label
                dark
                v-if="type == 'بوابة  VIP الداخلية'"
                color="red"
                >{{ type }}</v-chip
              >
            </v-row>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <Pagination />
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      fullName: "",
      isReject: "",
      IsAttend: "",
      type: "",
      HasCar: "",
      startAt: "",
      expireAt: "",
      count: 0,
      organizationId: "",
      headers: [
        {
          text: this.$t('rqm-altkhwyl'),
          value: "permit.sepuence",
        },
        {
          text: this.$t('alism-althlathy-llzaer'),
          value: "fullName",
        },
        {
          text: this.$t('alsfh'),
          value: "jobTitle",
        },
        {
          text: this.$t('aljhh-alzaerh'),
          value: "visiting",
        },
        {
          text: this.$t('alajhzh'),
          value: "devices",
        },
        {
          text: this.$t('nwa-alajlh'),
          value: "carName",
        },
        {
          text: this.$t('lwn-alajlh'),
          value: "carColor",
        },
        {
          text: this.$t('mwdyl-alajlh'),
          value: "caryear",
        },
        {
          text: this.$t('nwa-altkhwyl'),
          value: "type",
        },
        {
          text: this.$t('aljhh-altalbh-lltkhwyl'),
          value: "permit.organization.name",
        },
        {
          text: this.$t('mlahzh'),
          value: "note",
        },
        {
          text: this.$t('akhr-tarykh-wwqt-tsjyl-hdhwr'),
          value: "attends",
        },
      ],
    };
  },

  created() {
    this.getItems();
    this.$global.dispatch(`getOrganization`);
    // this.$global.dispatch(`getGroup`);
  },

  methods: {
    async getItems() {
      this.$global.state.loading = true;
      try {
        let url = `PermitData?Sequence=3&PageIndex=${
          this.$global.state.filter.pageIndex
        }&PageSize=${this.$global.state.filter.pageSize}&FullName=${
          this.fullName
        }&IsReject=${this.isReject == null ? "" : this.isReject}&IsAttend=${
          this.IsAttend == null ? "" : this.IsAttend
        }&Type=${this.type == null ? "" : this.type}&HasCar=${
          this.HasCar == null ? "" : this.HasCar
        }&ExpireAt=${this.expireAt}&StartAt=${
          this.startAt
        }&IsDeleted=false&Sort=desc&OrganizationId=`;

        const res = await this.$http.get(url);
        this.items = res.data.result;
        this.count = res.data.count;
        this.$global.state.length =
          res.data.count == undefined
            ? 0
            : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
        for (let i = 0; i < this.items.length; i++) {
          this.items[i].type = this.items[i].type.split(",");
        }
      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },
  },
};
</script>
