var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mx-1 my-3",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:500ms",value:(_vm.getItems),expression:"getItems",arg:"500ms"}],staticClass:"mr-1",attrs:{"label":_vm.$t('bhth-bwasth-alism-althlathy'),"hide-details":"","prepend-inner-icon":"search","dense":"","outlined":"","filled":""},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{staticClass:"mr-1",attrs:{"clearable":"","item-text":"key","item-value":"value","items":[
          { key: _vm.$t('almqbwlyn'), value: false },
          { key: _vm.$t('almrfwdhyn'), value: true },
        ],"label":_vm.$t('halh-alzaer'),"hide-details":"","prepend-inner-icon":"search","dense":"","outlined":"","filled":""},on:{"click:clear":function($event){_vm.isReject = ''},"input":function($event){return _vm.getItems()}},model:{value:(_vm.isReject),callback:function ($$v) {_vm.isReject=$$v},expression:"isReject"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{staticClass:"mr-1",attrs:{"clearable":"","item-text":"key","item-value":"value","items":[
          { key: 'غير الحاضرين', value: false },
          { key: 'الحاضرين', value: true },
        ],"label":_vm.$t('alhdhwr'),"hide-details":"","prepend-inner-icon":"search","dense":"","outlined":"","filled":""},on:{"click:clear":function($event){_vm.IsAttend = ''},"input":function($event){return _vm.getItems()}},model:{value:(_vm.IsAttend),callback:function ($$v) {_vm.IsAttend=$$v},expression:"IsAttend"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{staticClass:"mr-1",attrs:{"clearable":"","item-text":"key","item-value":"value","items":[
          { key: 'الحضور بدون عجلة', value: false },
          { key: 'الحضور بعجلة', value: true },
        ],"label":_vm.$t('alajlat'),"hide-details":"","prepend-inner-icon":"search","dense":"","outlined":"","filled":""},on:{"click:clear":function($event){_vm.HasCar = ''},"input":function($event){return _vm.getItems()}},model:{value:(_vm.HasCar),callback:function ($$v) {_vm.HasCar=$$v},expression:"HasCar"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"clearable":"","dense":"","item-text":"key","item-value":"key","items":[
          { key: 'بوابة الB3' },
          { key: 'بوابة الموظفين' },
          { key: 'بوابة VIP' },
          { key: 'بوابة  VIP الداخلية' },
          { key: 'بوابة الموظفين الداخلية' },
        ],"placeholder":"نوع التخويل","filled":"","outlined":"","hide-details":"auto"},on:{"input":function($event){return _vm.getItems()}},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":_vm.$t('wqt-bdayh-altkhwyl'),"outlined":"","filled":"","append-icon":"mdi-calendar","readonly":"","hide-details":"auto"},model:{value:(_vm.startAt),callback:function ($$v) {_vm.startAt=$$v},expression:"startAt"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"locale":"ar","color":"primary"},on:{"input":function($event){return _vm.getItems()}},model:{value:(_vm.startAt),callback:function ($$v) {_vm.startAt=$$v},expression:"startAt"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":_vm.$t('wqt-anthaa-altkhwyl'),"outlined":"","filled":"","append-icon":"mdi-calendar","readonly":"","hide-details":"auto"},model:{value:(_vm.expireAt),callback:function ($$v) {_vm.expireAt=$$v},expression:"expireAt"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"locale":"ar","color":"primary"},on:{"input":function($event){return _vm.getItems()}},model:{value:(_vm.expireAt),callback:function ($$v) {_vm.expireAt=$$v},expression:"expireAt"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{attrs:{"to":'PermitReportsPrint/Sequence=3&IsDeleted=false&Sort=desc&PageIndex=1&PageSize=' +
          _vm.count +
          '&FullName=' +
          _vm.fullName +
          '&IsReject=' +
          (_vm.isReject == null ? '' : _vm.isReject) +
          '&IsAttend=' +
          (_vm.IsAttend == null ? '' : _vm.IsAttend) +
          '&Type=' +
          (_vm.type == null ? '' : _vm.type) +
          '&HasCar=' +
          (_vm.HasCar == null ? '' : _vm.HasCar) +
          '&ExpireAt=' +
          _vm.expireAt +
          '&StartAt=' +
          _vm.startAt,"block":"","color":"primary"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('tbaah-tqryr')))])])],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('h3',{staticClass:"text-center mt-4"},[_vm._v(" -- "+_vm._s(_vm.$t('alntyjh'))+": "),_c('v-chip',{attrs:{"label":""}},[_c('h3',[_vm._v(_vm._s(_vm.count))])]),_vm._v(" -- ")],1),_c('v-data-table',{staticClass:"mytable",attrs:{"items-per-page":_vm.$global.state.filter.pageSize,"items":_vm.items,"loading":_vm.$global.state.loading,"headers":_vm.headers,"hide-default-footer":"","loading-text":_vm.$t('loading'),"no-data-text":_vm.$t('no-data'),"id":_vm.$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'},scopedSlots:_vm._u([{key:"item.attends",fn:function({ item }){return [(item.attends.length > 0)?_c('div',{staticClass:"teal--text"},[_vm._v(" "+_vm._s(new Date(item.attends[0].time) .toLocaleDateString("sv") .split("T")[0])+", "),_c('span',[_vm._v(_vm._s(new Date(item.attends[0].time).toLocaleString().split(",")[1]))])]):_c('div',[_c('h4',[_vm._v("لم يحضر")])])]}},{key:"item.type",fn:function({ item }){return _vm._l((item.type),function(type,i){return _c('div',{key:i},[_c('v-row',{staticClass:"ma-1"},[(type == 'بوابة الB3')?_c('v-chip',{attrs:{"small":"","label":"","dark":"","color":"orange"}},[_vm._v(_vm._s(type))]):_vm._e(),(type == 'بوابة الموظفين')?_c('v-chip',{attrs:{"small":"","label":"","dark":"","color":"indigo"}},[_vm._v(_vm._s(type))]):_vm._e(),(type == 'بوابة VIP')?_c('v-chip',{attrs:{"small":"","label":"","dark":"","color":"red"}},[_vm._v(_vm._s(type))]):_vm._e(),(type == 'بوابة الموظفين الداخلية')?_c('v-chip',{attrs:{"small":"","label":"","dark":"","color":"indigo"}},[_vm._v(_vm._s(type))]):_vm._e(),(type == 'بوابة  VIP الداخلية')?_c('v-chip',{attrs:{"small":"","label":"","dark":"","color":"red"}},[_vm._v(_vm._s(type))]):_vm._e()],1)],1)})}}])})],1),_c('Pagination')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }